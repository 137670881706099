<template>
  <div class="page">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'Package',
  data() {
    return {
      wH: 0,
    };
  },
  mounted() {
    this.wH = window.innerHeight;
  },
};
</script>
<style lang="scss" scoped>
  .page {
    width: 750px;
    margin: auto;
    font-family: PingFangSC-Regular, PingFang SC;
    min-height: 100%;
    background: #F9F9F9;
    position: relative;
  }
</style>
<style lang="scss">
  @import "@/assets/scss/index.scss";
</style>
